import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Loader,Modal,Button,Header, DecodeBase64 } from "../Components";
import { API_SWIP_URLS } from "../Shared/Constant";
import { POST } from "../Shared/Axios";
import "./buy-more.scss";

function BuyMore() {
  const [congs, setCongs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [congsData, setCongsData] = useState("");
  const location = useLocation();
  const getMsisdn=window.localStorage.getItem("msisdn")
 
  const getMoreChances = () => {
    setIsLoading(true);
    const data = {
      operatorId: "mtn_congo",
      packId: "dailytopup",
      userId: getMsisdn,
    };
    POST(API_SWIP_URLS.Buy, data)
      .then((res) => {
        setCongsData(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const onHandleBuy = () => {
    getMoreChances();
    setCongs(true);
  };

  return (
    <div className="main">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className="bg-white rounded-lg  mt-4 ">
            <div className="bg-white text-center text-sky-800 p-1 border-l-8 border-l-sky-800 rounded-lg ">
              <h4 className="mt-4 text-center text-black text-2xl ">
                {location?.state?.questions?.packs[0]?.packName?DecodeBase64(location?.state?.questions?.packs[0]?.packName):DecodeBase64(location?.state?.questions?.packs[0]?.packText[0]?.description)}
              </h4>
              <p className="my-2 text-gray-500 text-3xl font-medium">
                {location?.state?.questions.packs[0]?.packName?DecodeBase64(location?.state?.questions?.packs[0]?.description):DecodeBase64(location?.state?.questions?.packs[0]?.packText[0]?.description)}
              </p>

              <div className="flex flex-col justify-center items-center">
                <Button
                  name={location?.state?.questions?.packs[0]?.packName?DecodeBase64(
                    location?.state?.questions?.packs[0]?.buttonText
                  ):DecodeBase64(location?.state?.questions?.packs[0]?.packText[0]?.buttonText)}
                  className="bg-sky-800 text-white text-xl font-semibold rounded-full py-2 px-4 capitalize button-theme m-2"
                  handleClick={onHandleBuy}
                />
                <Link to="/home" className="text-blue-500 text-md ">
                  {location?.state?.questions?.packs[0]?.packName?DecodeBase64(location?.state?.questions?.packs[0]?.notNow):DecodeBase64(location?.state?.questions?.packs[0]?.packText[0]?.notNow)}
                </Link>
              </div>
            </div>
          </div>
          {congs && (
            <Modal
              type="congress"
                title={congsData?.info?.title}
              subTitle={congsData?.info?.subtitle}
              sub={congsData?.info?.text}
              button={congsData?.info?.buttonText}
              congrats={congsData}
            />
          )}
        </>
      )}
    </div>
  );
}

export default BuyMore;
