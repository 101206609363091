import React, { useCallback, useRef } from "react";
import TinderCard from "react-tinder-card";
import { DecodeBase64 } from "../Components";
import { POST } from "../Shared/Axios";
import { API_SWIP_URLS } from "../Shared/Constant";
import { SendGuiDataEvents } from "../commonScript";

const CardTemp = ({ questions, startData, childRefs, setCurrentIndex,setCount }) => {
  const getMsisdn=window.localStorage.getItem("msisdn")
  // const swiped = (direction, index) => {
  //   setCurrentIndex(index - 1);
  //   setCount(10)
  //   console.log(direction, "dir");
  //   if (direction == "left") {
  //     const data = {
  //       userId:getMsisdn,
  //       sessionId: startData.sessionId,
  //       operatorId: "mtn_congo",
  //       useragent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
  //       answers: [
  //         {
  //           answer: false,
  //           questionId: questions[index]?.questionId,
  //           timeout: 1,
  //         },
  //       ],
  //     };
  //     POST(API_SWIP_URLS.Answer, data)
  //       .then((res) => {
  //         console.log(res);
          
  //       })
  //       .catch((err) => err);
  //   } else if (direction == "right") {
  //     const data = {
  //       userId: getMsisdn,
  //       sessionId: startData.sessionId,
  //       operatorId: "mtn_congo",
  //       useragent:
  //         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
  //       answers: [
  //         {
  //           answer: true,
  //           questionId: questions[index]?.questionId,
  //           timeout: 0,
  //         },
  //       ],
  //     };
  //     POST(API_SWIP_URLS.Answer, data)
  //       .then((res) => {
  //         console.log(res);
         
  //       })
  //       .catch((err) => err);
  //   } 
  // };

  const swipedCards = useRef([]);
  const swiped = useCallback(
    (direction, index) => {
      if (swipedCards.current.includes(index)) {
        return; // Card has already been swiped, ignore the swipe event
      }

      swipedCards.current.push(index);
      setCurrentIndex(index - 1);
      setCount(10);
      console.log(direction, "dir");
      if (direction === "left") {
        const data = {
          userId: getMsisdn && getMsisdn,
          sessionId: startData.sessionId,
          operatorId: "mtn_congo",
          useragent:
            "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
          answers: [
            {
              answer: false,
              questionId: questions[index]?.questionId,
              timeout: 1,
            },
          ],
        };
        POST(API_SWIP_URLS.Answer, data)
          .then((res) => {
            console.log(res);
            let guiEvents={};
            guiEvents['page']="question";
            guiEvents["event"] = "question_played";
            SendGuiDataEvents(guiEvents);

          })
          .catch((err) => err);
      } else if (direction === "right") {
        const data = {
          userId: getMsisdn && getMsisdn,
          sessionId: startData.sessionId,
          operatorId: "mtn_congo",
          useragent:
            "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
          answers: [
            {
              answer: true,
              questionId: questions[index]?.questionId,
              timeout: 0,
            },
          ],
        };
        POST(API_SWIP_URLS.Answer, data)
          .then((res) => {
            console.log(res);
            let guiEvents={};
            guiEvents['page']="question";
            guiEvents["event"] = "question_played";
            SendGuiDataEvents(guiEvents);

          })
          .catch((err) => err);
      } else {
        if (direction === "up") {
          const data = {
            userId: getMsisdn && getMsisdn,
            sessionId: startData.sessionId,
            operatorId: "mtn_congo",
            // quizName: "SWIPEQUIZ",
            // quizType: "SWIPEQUIZ",
            useragent:
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:106.0) Gecko/20100101 Firefox/106.0",
            answers: [
              {
                userAnswer: "FALSE",
                questionId: questions[index]?.questionId,
                timeout: 1,
              },
            ],
          };
          POST(API_SWIP_URLS.Answer, data)
            .then((res) => {
              console.log(res, "up");
              let guiEvents={};
              guiEvents['page']="question";
              guiEvents["event"] = "question_played";
              SendGuiDataEvents(guiEvents);
  
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    },
    [questions, startData.sessionId, setCurrentIndex, setCount]
  );


  return (
    <div>
      {questions.length &&
        questions?.map((item, idx) => {
          return (
              <TinderCard
                  ref={childRefs[idx]}
                  swipeRequirementType={"position"}
                  className="swip_cards"
                  // onSwipeRequirementFulfilled={(dir) => swiped(dir, idx)}
                  // swipeThreshold={150}
                  onSwipe={(dir) => swiped(dir, idx)}
                  swipeThreshold={300}
                  preventSwipe={("down")}
                  key={idx}

                >
                  <p className="font-bold text-base px-1 mb-2 pt-1">
                    {DecodeBase64(item.questionText)}
                  </p>
                  <div className=" w-11/12 mx-auto rounded-lg">
                    <img
                      src={item.imageUrl}
                      alt={item.questionText}
                      className="rounded-lg"
                      height="100%"
                      width="100%"
                    />
                  </div>
                </TinderCard>
            
          );
        })}
    </div>
  );
};

export default React.memo(CardTemp);
